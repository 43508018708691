
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as testuIHN47Vh_C1tmOl545DicizmXB_oJj4COR6x_qStdScMeta } from "/opt/buildhome/repo/pages/test.vue?macro=true";
import { default as legacyKIas2PJMkGNlB34OLcpStfz_bcqh2cczvKw5_45PSBlJAMeta } from "/opt/buildhome/repo/pages/legacy.vue?macro=true";
import { default as clienttestGfCa8a2_45Bg0I3_45noZVmfNTJjtrMiXn_45JuffApoGMRiYMeta } from "/opt/buildhome/repo/pages/clienttest.vue?macro=true";
import { default as cookieinfoorrfT1X_a1MISOUEipXQ96kcApnIeovsa_19Hgx_ucwMeta } from "/opt/buildhome/repo/pages/cookieinfo.vue?macro=true";
import { default as loginKMdcZdjxVWBby7qzc8iax1m1i6js8gN3t8PvZM_RwR8Meta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as ssoLoginojbpxvnfsPjvzJ8xuCGIo3fOhiyomBPaCsor582zEm0Meta } from "/opt/buildhome/repo/pages/ssoLogin.vue?macro=true";
import { default as sponsoredArticlesyFhVrKxfsjFPthL5wReAbiULitnNouxbHbi8TCSvG60Meta } from "/opt/buildhome/repo/pages/sponsoredArticles.vue?macro=true";
import { default as sponsoredfq4ErJnPFHWBEpzU2sxorp0VTQOzwDiJsFx4CTv6_qcMeta } from "/opt/buildhome/repo/pages/sponsored.vue?macro=true";
import { default as indexsSHEynImpmVJQBsgEWtpFbTIimvAlNbjHy0W37sieOIMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as profilelYJWJLXKCDoxakjw7sVPapCxOwP6E9HYlUzzKi6Xhy0Meta } from "/opt/buildhome/repo/pages/account/profile.vue?macro=true";
import { default as loginsecurityEL0Mk4ppj7JsAU5PpThBz751WoLjAITw2_HiOAflYy0Meta } from "/opt/buildhome/repo/pages/account/loginsecurity.vue?macro=true";
import { default as savedArticlesRn0lylgoTP3f4sGTTQtkZSSEzSL2eboC0kYDGMKGZ1kMeta } from "/opt/buildhome/repo/pages/account/savedArticles.vue?macro=true";
import { default as decisionchainsVbDd_MNA7Vy2qFrYtii_45WpliXu8a2Rs66trvmYlPpTsMeta } from "/opt/buildhome/repo/pages/account/decisionchains.vue?macro=true";
import { default as newsletterJvVDqu527IILkYvJvCLXifJdzBQyrGvtduNVSh_45qPMQMeta } from "/opt/buildhome/repo/pages/account/newsletter.vue?macro=true";
import { default as subscriptionV6NtWMj1DyVaX0zlzWTuNYTRGSLzBxHKUOnEqs_FWxcMeta } from "/opt/buildhome/repo/pages/account/subscription.vue?macro=true";
import { default as userCreateAd20DSRaJXQW_45p7mNOP0ZPLO4pDWKEOtu_45mUWv_45fNe0Meta } from "/opt/buildhome/repo/pages/userCreate.vue?macro=true";
import { default as forgotPassword2d9h4ddAmdrx5DWOy69v4odaTLhdb_45_45G04Y0JjXEiOgMeta } from "/opt/buildhome/repo/pages/user/forgotPassword.vue?macro=true";
import { default as userSetPasswordu1APOcfO0OoTq8HhFeD0I7XUS_Y7sn8eBw3MhGb_RZoMeta } from "/opt/buildhome/repo/pages/userSetPassword.vue?macro=true";
import { default as copyrightBnkjBH8DJ6IKvYyHg9Yd_kfEXgH7XXbfGqFRlnBeQzMMeta } from "/opt/buildhome/repo/pages/copyright.vue?macro=true";
import { default as typographycT9iRWTtYPPD9VmzH1edaA5od4wBPzrJU7WPguxASlwMeta } from "/opt/buildhome/repo/pages/typography.vue?macro=true";
import { default as searchVZHeG3_X8t_IjAUUzx95piBYJ_XkAO6zB8qw3F4DYRcMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as newslettervbstlW1aXPob_kb_S6_45vN2SKlT_45S3Ae_D5tttUKfvLsMeta } from "/opt/buildhome/repo/pages/newsletter.vue?macro=true";
import { default as jobs_45JgUSdYDmJFKqL1y3bOMzoUAGdLCXz9WyC6Gn5TAHW4Meta } from "/opt/buildhome/repo/pages/jobs.vue?macro=true";
import { default as jobAdMkMNj5bPyuYyxVuNM7Tv5tRQWu9zRSvNPW0il3w_SFIMeta } from "/opt/buildhome/repo/pages/jobAd.vue?macro=true";
import { default as jobchangeenbzg4rLG_yCCWDm3rN9pUH9vuiUMS_45dCLAsspB_gtUMeta } from "/opt/buildhome/repo/pages/jobchange.vue?macro=true";
import { default as articlevUbztdBt_45kY9p5_45KbWF1YIXt8Oq_45NB267FWkN8Rjz8QMeta } from "/opt/buildhome/repo/pages/article.vue?macro=true";
import { default as namesttUIq_45SCFsXK_8aKedd7vQg63pMDKyKJUxKe5lYUsE0Meta } from "/opt/buildhome/repo/pages/names.vue?macro=true";
import { default as birthdaysYigHStpQx6z55ZDwGE33fOpNxj8Kbyl5i57O6F4nxAEMeta } from "/opt/buildhome/repo/pages/birthdays.vue?macro=true";
import { default as podcastsghWyXlLzvAVINHO7vuhyBUh5kMBdWjsqBjDyL8BrgyYMeta } from "/opt/buildhome/repo/pages/podcasts.vue?macro=true";
import { default as podcastEtrmAb843_45pb3IYiAowFB3L_qVs73tz_s_45qCq43acRQMeta } from "/opt/buildhome/repo/pages/podcast.vue?macro=true";
import { default as magazineyhX2FNuDDV1VCYbFTG8xpOhzWbwqbdnauDno_45ebqTyEMeta } from "/opt/buildhome/repo/pages/magazine.vue?macro=true";
import { default as subjectH8eSVCqdEl9F5uzaZhreysJcAx44XcETRfkz7kLT5_IMeta } from "/opt/buildhome/repo/pages/subject.vue?macro=true";
import { default as personlCkc_45Vi8RcD_XBtVRfX6SUxPJkgVQ5_KLGzHUJDhSBYMeta } from "/opt/buildhome/repo/pages/person.vue?macro=true";
import { default as writer8K52r8iOLR6Y92377lIMrt6s_IST0QTiM2vmae303vAMeta } from "/opt/buildhome/repo/pages/writer.vue?macro=true";
import { default as newstPGn1Xn_45n1pV6ijNdEgEzqjAoZNxuYMOC2w1agEX1w8Meta } from "/opt/buildhome/repo/pages/news.vue?macro=true";
import { default as debategxsdgZTwOzID5gW61su4W0rH3uydF2Yo0jEfbDG6iSQMeta } from "/opt/buildhome/repo/pages/debate.vue?macro=true";
import { default as calendarlistfwO_45X44FIWMwk3hN0AKMajMY_451cEPrFubYUt5FXOQcUMeta } from "/opt/buildhome/repo/pages/calendarlist.vue?macro=true";
import { default as calendar7G_ZKYj7p3K4ToVKHWSuO_45xnWt0JLVq1wsfi13UwwSkMeta } from "/opt/buildhome/repo/pages/calendar.vue?macro=true";
import { default as qaList6EOwCvDC9Imomw5JKBaVtaouAkrAL4kCQAXfOQiBxbgMeta } from "/opt/buildhome/repo/pages/qaList.vue?macro=true";
import { default as qaSyj5Vv5jHryRpe3NayMp4RQUdoi1wDEcj3vdNo7fRPYMeta } from "/opt/buildhome/repo/pages/qa.vue?macro=true";
import { default as rss6iXlgMo0Saczc9WqnYCmhcut0UnyZSgTXQvMpOnMYJkMeta } from "/opt/buildhome/repo/pages/rss.vue?macro=true";
import { default as electionsaCLWWEzSmwPoSOUKFQ06gbnoOBDIqg3cFBTWSNQEg0cMeta } from "/opt/buildhome/repo/pages/ct/elections.vue?macro=true";
import { default as election1gWdlDWRaLG3ACS5SbqlJ69LiIjD68VFGq9Il404stYMeta } from "/opt/buildhome/repo/pages/ct/election.vue?macro=true";
import { default as electionCandidateTestErekm1u9CwcTcoH_45vhfI2RR91y_45o0HhD_6ciOKLP1BkMeta } from "/opt/buildhome/repo/pages/ct/electionCandidateTest.vue?macro=true";
import { default as electionCandidateTestResult_zG2M9accn8rGolo_r_WjA_TP7qjdtwudWYM70etgrwMeta } from "/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue?macro=true";
import { default as electionProfilePageK3VW8NbIN_45ioILW4UQMGGziyMu1leS8aGdceJbQd6dAMeta } from "/opt/buildhome/repo/pages/ct/electionProfilePage.vue?macro=true";
import { default as electionRobotArticleUacOimYH49H9LsCBFySymyGUObvovSGC6_sWXPQK94IMeta } from "/opt/buildhome/repo/pages/ct/electionRobotArticle.vue?macro=true";
import { default as electionBallotAkaj49qexhso5jiLZzJMbYiMwv0gxpNTf2ONVhM20ZQMeta } from "/opt/buildhome/repo/pages/ct/electionBallot.vue?macro=true";
import { default as termsM_RN9_459iuNVtH23UOLrjvoINYU4b2nnq0grhd_RmiYYMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as termNFMIQ_PMkaujhYlHeuBiHQJYtEm3saDlugGWqOL04tkMeta } from "/opt/buildhome/repo/pages/term.vue?macro=true";
import { default as paper_hobq1RsET7WGynSPEu1BX4VHMaRtrUq0rRPF2f2Vb8Meta } from "/opt/buildhome/repo/pages/paper.vue?macro=true";
import { default as indexSc7qEHty3deeMhDNMhBK88IN6QbKx_buR9bGGpmWJCgMeta } from "/opt/buildhome/repo/pages/folkemodet/index.vue?macro=true";
import { default as eventsr5J61FXo_x9VUk52WKimC_lstX_45zimnqpcogJLUq4lwMeta } from "/opt/buildhome/repo/pages/folkemodet/events.vue?macro=true";
import { default as adsQXSzgfu0CPZQLYCMj1eP3m0pKxVy6EaBZI7FQGFcRskMeta } from "/opt/buildhome/repo/pages/ads.vue?macro=true";
import { default as coursesQyWlVFVYY4SbqrviohXjI_jK2SA_HsH4TSzy3JemKLwMeta } from "/opt/buildhome/repo/pages/courses.vue?macro=true";
import { default as courseAH8AzS6SN8fm_xZ58jmeeb_Pxp2pSU5iGzH9A4EdJg4Meta } from "/opt/buildhome/repo/pages/course.vue?macro=true";
import { default as networks04Mn2rj_9WTs2AfBoOWaMI0SmtZ8kZxkPthpt58aJy8Meta } from "/opt/buildhome/repo/pages/networks.vue?macro=true";
import { default as networkfuYIhDy_45Y1gOmL4R_s5ZZHfYIykL_45L4nViQI8xBUEj8Meta } from "/opt/buildhome/repo/pages/network.vue?macro=true";
import { default as conferencesRkod_UrYL9jr683tq2coXkauSykNwHfrWO4BlLDV7M8Meta } from "/opt/buildhome/repo/pages/conferences.vue?macro=true";
import { default as conferenceUM_QytQQFLpDmcymMQzcMx8N6xDcHo6Rn848nk0lO3UMeta } from "/opt/buildhome/repo/pages/conference.vue?macro=true";
import { default as facilitiesRPRwAHMHfcqvifJYJWC4CGHUn6poBE7vZ3Z2Nop43cgMeta } from "/opt/buildhome/repo/pages/facilities.vue?macro=true";
import { default as commentsa_Aj706MYmwHP_45sH6Y3jY9nzpKFmzrIG53_45I_456dpuJkMeta } from "/opt/buildhome/repo/pages/comments.vue?macro=true";
import { default as politicalSpeeches7Dx7c6HfnFaNebqtG_NPGzj5x_LRCI4OLktgPSXyU_IMeta } from "/opt/buildhome/repo/pages/politicalSpeeches.vue?macro=true";
import { default as b2c_45frontpagey1Bk7gBrGdSUW6XjwwqOHEdh6Di2_nSmZLp6Ta0XlrYMeta } from "/opt/buildhome/repo/pages/b2c-frontpage.vue?macro=true";
import { default as prices6d4LxWc2omt5Cpk4box86hrKcGoePl45sVlas5KHd_45gMeta } from "/opt/buildhome/repo/pages/prices.vue?macro=true";
import { default as productsY2XB_454cDKLta72k90vx8ISbqhUFoUiYZTBg44pjiRE4Meta } from "/opt/buildhome/repo/pages/products.vue?macro=true";
import { default as raadhussvar7jqThI9UpJJpu3Fm_uPb_45We28CHNjHqkzuYNcgvU9_45AMeta } from "/opt/buildhome/repo/pages/dk/raadhussvar.vue?macro=true";
import { default as subscriptionFWGo1Xjzp99YSRjwECHVWKB8clRoOZGH_h5d4EREFtgMeta } from "/opt/buildhome/repo/pages/subscription.vue?macro=true";
import { default as customerServiceOv57LmMMuyKQK3wLCvhiMm9lXw3N32jkwhheu6WlHNQMeta } from "/opt/buildhome/repo/pages/customerService.vue?macro=true";
import { default as podcast_45surveyGtJO5D5DJYXbgLBT41aese7sEkiOIFqC0_45wmArcyIZ0Meta } from "/opt/buildhome/repo/pages/podcast-survey.vue?macro=true";
import { default as yearbookpIQRCEXV61pdr7UduxLtyDpKOsg8N38DnNzmxa0WnuQMeta } from "/opt/buildhome/repo/pages/dk/yearbook.vue?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "test",
    path: "/test",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/test.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: {"url":"/quiz.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "clienttest",
    path: "/ct",
    component: () => import("/opt/buildhome/repo/pages/clienttest.vue")
  },
  {
    name: "quizId",
    path: "/quiz/:id",
    meta: {"url":"/quiz.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "regering",
    path: "/regering",
    meta: {"url":"/regering.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "cookieinfo",
    path: "/cookieinfo",
    component: () => import("/opt/buildhome/repo/pages/cookieinfo.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "ssoLogin",
    path: "/sso-login",
    component: () => import("/opt/buildhome/repo/pages/ssoLogin.vue")
  },
  {
    name: "birthdayGreeting",
    path: "/foedselsdagehilsen/:paramid",
    meta: {"url":"/BirthdayGreeting.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "accessmail",
    path: "/accessmail",
    meta: {"url":"/accessmail.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "afmeld",
    path: "/afmeld",
    meta: {"url":"/afmeld.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "afmeld_paper",
    path: "/:paper/afmeld",
    meta: {"url":"/afmeld.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "sponsored",
    path: "/sponsoreret",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsoredArticles.vue")
  },
  {
    name: "sponsored_paper",
    path: "/:paper/sponsoreret",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsoredArticles.vue")
  },
  {
    name: "sponsored_id",
    path: "/sponsoreret/:id",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsored.vue")
  },
  {
    name: "sponsored_paper_id",
    path: "/:paper/sponsoreret/:id",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsored.vue")
  },
  {
    name: "magasin_vis",
    path: "/magasin/vis/:id",
    meta: {"url":"/magasin/vis"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "kandidater_ft19",
    path: "/kandidater/ft19",
    meta: {"url":"/kandidater/ft19"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "kandidater_ep19",
    path: "/kandidater/ep19",
    meta: {"url":"/kandidater/ep19"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "account",
    path: "/konto",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "account_profile",
    path: "/konto/profil",
    component: () => import("/opt/buildhome/repo/pages/account/profile.vue")
  },
  {
    name: "account_loginsecurity",
    path: "/konto/login-sikkerhed",
    component: () => import("/opt/buildhome/repo/pages/account/loginsecurity.vue")
  },
  {
    name: "saved_articles",
    path: "/konto/gemte-artikler",
    component: () => import("/opt/buildhome/repo/pages/account/savedArticles.vue")
  },
  {
    name: "account_decisionchains",
    path: "/konto/beslutningskaeder",
    component: () => import("/opt/buildhome/repo/pages/account/decisionchains.vue")
  },
  {
    name: "account_newsletter",
    path: "/konto/nyhedsbrev",
    component: () => import("/opt/buildhome/repo/pages/account/newsletter.vue")
  },
  {
    name: "account_subscription",
    path: "/konto/abonnement",
    component: () => import("/opt/buildhome/repo/pages/account/subscription.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: {"url":"/admin"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "nejtak",
    path: "/nejtak",
    meta: {"url":"/nejtak.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "usercreate",
    path: "/usercreate",
    component: () => import("/opt/buildhome/repo/pages/userCreate.vue")
  },
  {
    name: "userconfirmation",
    path: "/userconfirmation",
    meta: {"url":"/userconfirmation"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "useravatar",
    path: "/useravatar",
    meta: {"url":"/useravatar"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "userforgotpassword",
    path: "/adgangskode",
    component: () => import("/opt/buildhome/repo/pages/user/forgotPassword.vue")
  },
  {
    name: "usersetpassword",
    path: "/usersetpassword",
    component: () => import("/opt/buildhome/repo/pages/userSetPassword.vue")
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/opt/buildhome/repo/pages/copyright.vue")
  },
  {
    name: "licens",
    path: "/licens",
    meta: {"url":"/licens"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "legacy-test",
    path: "/legacy-test",
    meta: {"url":"/xx.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "typography",
    path: "/typography",
    component: () => import("/opt/buildhome/repo/pages/typography.vue")
  },
  {
    name: "search",
    path: "/soeg",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "paper_search",
    path: "/:paper/soeg",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "searchwords",
    path: "/soeg/:words",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "paper_searchwords",
    path: "/:paper/soeg/:words",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "newsletter",
    path: "/nyhedsbrev",
    component: () => import("/opt/buildhome/repo/pages/newsletter.vue")
  },
  {
    name: "jobs",
    path: "/job",
    component: () => import("/opt/buildhome/repo/pages/jobs.vue")
  },
  {
    name: "job",
    path: "/job/:id",
    component: () => import("/opt/buildhome/repo/pages/jobAd.vue")
  },
  {
    name: "jobchange-index",
    path: "/jobskifte",
    component: () => import("/opt/buildhome/repo/pages/jobchange.vue")
  },
  {
    name: "jobchange",
    path: "/jobskifte/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "paper_jobchange-index",
    path: "/:paper/jobskifte",
    component: () => import("/opt/buildhome/repo/pages/jobchange.vue")
  },
  {
    name: "paper_jobchange",
    path: "/:paper/jobskifte/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "article",
    path: "/artikel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "paper_article",
    path: "/:paper/artikel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "names",
    path: "/navnenyt/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "paper_names",
    path: "/:paper/navnenyt/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "names-index",
    path: "/navnenyt",
    component: () => import("/opt/buildhome/repo/pages/names.vue")
  },
  {
    name: "paper_names-index",
    path: "/:paper/navnenyt",
    component: () => import("/opt/buildhome/repo/pages/names.vue")
  },
  {
    name: "birthdays",
    path: "/foedselsdage",
    component: () => import("/opt/buildhome/repo/pages/birthdays.vue")
  },
  {
    name: "paper_birthdays-index",
    path: "/:paper/foedselsdage",
    component: () => import("/opt/buildhome/repo/pages/birthdays.vue")
  },
  {
    name: "podcast",
    path: "/podcast",
    component: () => import("/opt/buildhome/repo/pages/podcasts.vue")
  },
  {
    name: "podcastId",
    path: "/podcast/:podcast",
    component: () => import("/opt/buildhome/repo/pages/podcast.vue")
  },
  {
    name: "magazine",
    path: "/magasin",
    component: () => import("/opt/buildhome/repo/pages/magazine.vue")
  },
  {
    name: "subject",
    path: "/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/subject.vue")
  },
  {
    name: "paper_subject",
    path: "/:paper/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/subject.vue")
  },
  {
    name: "person",
    path: "/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue")
  },
  {
    name: "paper_person",
    path: "/:paper/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue")
  },
  {
    name: "writer_new",
    path: "/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue")
  },
  {
    name: "paper_writer",
    path: "/:paper/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue")
  },
  {
    name: "news",
    path: "/nyheder",
    component: () => import("/opt/buildhome/repo/pages/news.vue")
  },
  {
    name: "debate",
    path: "/debat",
    component: () => import("/opt/buildhome/repo/pages/debate.vue")
  },
  {
    name: "decisionchain",
    path: "/decisionchain",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "decisionchain_id",
    path: "/decisionchain/:id",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "paper_decisionchain",
    path: "/:paper/decisionchain",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "paper_decisionchain_id",
    path: "/:paper/decisionchain/:id",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "paper_news",
    path: "/:paper/nyheder",
    component: () => import("/opt/buildhome/repo/pages/news.vue")
  },
  {
    name: "paper_debate",
    path: "/:paper/debat",
    component: () => import("/opt/buildhome/repo/pages/debate.vue")
  },
  {
    name: "cal",
    path: "/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue")
  },
  {
    name: "calendar",
    path: "/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue")
  },
  {
    name: "paper_cal",
    path: "/:paper/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue")
  },
  {
    name: "paper_calendar",
    path: "/:paper/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue")
  },
  {
    name: "qa-index",
    path: "/ministersvar",
    component: () => import("/opt/buildhome/repo/pages/qaList.vue")
  },
  {
    name: "qa",
    path: "/ministersvar/:id",
    component: () => import("/opt/buildhome/repo/pages/qa.vue")
  },
  {
    name: "paper_qa-index",
    path: "/:paper/ministersvar",
    component: () => import("/opt/buildhome/repo/pages/qaList.vue")
  },
  {
    name: "paper_qa",
    path: "/:paper/ministersvar/:id",
    component: () => import("/opt/buildhome/repo/pages/qa.vue")
  },
  {
    name: "rss",
    path: "/rss/:id",
    component: () => import("/opt/buildhome/repo/pages/rss.vue")
  },
  {
    name: "paper_rss",
    path: "/:paper/rss/:id",
    component: () => import("/opt/buildhome/repo/pages/rss.vue")
  },
  {
    name: "elections",
    path: "/kandidattest",
    component: () => import("/opt/buildhome/repo/pages/ct/elections.vue")
  },
  {
    name: "election",
    path: "/kandidattest/:slug",
    component: () => import("/opt/buildhome/repo/pages/ct/election.vue")
  },
  {
    name: "electionTest",
    path: "/kandidattest/:slug/kandidattest",
    component: () => import("/opt/buildhome/repo/pages/ct/electionCandidateTest.vue")
  },
  {
    name: "electionTestResult",
    path: "/kandidattest/:slug/resultat",
    component: () => import("/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue")
  },
  {
    name: "electionProfile",
    path: "/kandidattest/:slug/profil/:person",
    component: () => import("/opt/buildhome/repo/pages/ct/electionProfilePage.vue")
  },
  {
    name: "electionArticle",
    path: "/kandidattest/:slug/valgresultat/:person",
    component: () => import("/opt/buildhome/repo/pages/ct/electionRobotArticle.vue")
  },
  {
    name: "electionBallot",
    path: "/kandidattest/:slug/valgkort",
    component: () => import("/opt/buildhome/repo/pages/ct/electionBallot.vue")
  },
  {
    name: "terms",
    path: "/handelsbetingelser",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: "term",
    path: "/handelsbetingelser/:termId",
    component: () => import("/opt/buildhome/repo/pages/term.vue")
  },
  {
    name: "paper",
    path: "/:paper",
    component: () => import("/opt/buildhome/repo/pages/paper.vue")
  },
  {
    name: "folkemodet",
    path: "/folkemodet",
    component: () => import("/opt/buildhome/repo/pages/folkemodet/index.vue")
  },
  {
    name: "folkemodet_events",
    path: "/folkemodet/program",
    component: () => import("/opt/buildhome/repo/pages/folkemodet/events.vue")
  },
  {
    name: "ads",
    path: "/annoncer",
    component: () => import("/opt/buildhome/repo/pages/ads.vue")
  },
  {
    name: "courses",
    path: "/kurser",
    component: () => import("/opt/buildhome/repo/pages/courses.vue")
  },
  {
    name: "course",
    path: "/kurser/:id",
    component: () => import("/opt/buildhome/repo/pages/course.vue")
  },
  {
    name: "networks",
    path: "/netvaerk",
    component: () => import("/opt/buildhome/repo/pages/networks.vue")
  },
  {
    name: "network",
    path: "/netvaerk/:id",
    component: () => import("/opt/buildhome/repo/pages/network.vue")
  },
  {
    name: "conferences",
    path: "/live-arrangementer",
    component: () => import("/opt/buildhome/repo/pages/conferences.vue")
  },
  {
    name: "conference",
    path: "/live-arrangementer/:id",
    component: () => import("/opt/buildhome/repo/pages/conference.vue")
  },
  {
    name: "facilities",
    path: "/partnerskaber-og-events",
    component: () => import("/opt/buildhome/repo/pages/facilities.vue")
  },
  {
    name: "comments-index",
    path: "/kommentarer",
    component: () => import("/opt/buildhome/repo/pages/comments.vue")
  },
  {
    name: "political-speeches-index",
    path: "/politiske-taler",
    component: () => import("/opt/buildhome/repo/pages/politicalSpeeches.vue")
  },
  {
    name: "frontpage",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/b2c-frontpage.vue")
  },
  {
    name: "pricing",
    path: "/priser",
    component: () => import("/opt/buildhome/repo/pages/prices.vue")
  },
  {
    name: "subscription",
    path: "/abonnement",
    component: () => import("/opt/buildhome/repo/pages/products.vue")
  },
  {
    name: "subscription_type",
    path: "/abonnement/:typeId",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/products.vue")
  },
  {
    name: "paper_raadhussvar-index",
    path: "/:paper/raadhussvar",
    component: () => import("/opt/buildhome/repo/pages/dk/raadhussvar.vue")
  },
  {
    name: "paper_raadhussvar",
    path: "/:paper/raadhussvar/:id",
    component: () => import("/opt/buildhome/repo/pages/qa.vue")
  },
  {
    name: "papers",
    path: "/nichemedier",
    component: () => import("/opt/buildhome/repo/pages/subscription.vue")
  },
  {
    name: "customer-service-index",
    path: "/kundeservice",
    component: () => import("/opt/buildhome/repo/pages/customerService.vue")
  },
  {
    name: "podcast-survey",
    path: "/lytter",
    component: () => import("/opt/buildhome/repo/pages/podcast-survey.vue")
  },
  {
    name: "yearbook-index",
    path: "/altinget20aar",
    component: () => import("/opt/buildhome/repo/pages/dk/yearbook.vue")
  },
  {
    name: "ct-electionCandidateTestResult",
    path: "/ct/electionCandidateTestResult",
    component: () => import("/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue")
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/misc/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/images/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/Scripts/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/scripts/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/css/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/pdffiles/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/arena",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/praktik",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/praktik/",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/glemtadgangskode",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/hotshot",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/hotshot/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/verdensmaal",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/verdensmaal/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/lyd",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/netv%C3%A6rk",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/navnenyt.aspx",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/jobannoncer",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/karriere",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/:paper/karriere",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/karriere/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/:paper/job",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/fm",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/verdensmaalsugen",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/verdensmål",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/verdensm%C3%A5l",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/energi",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/sponsoreret/-kompetenceudvikling-sikrer-korrekt-sagsbehandling",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/kandidater/",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/kandidater",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/valg",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/valg/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/etikogtro",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/priser-og-abonnementsbetingelser",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/1",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/2",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/3",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/4",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/5",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/6",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/7",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/8",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/9",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/10",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/podcast/11",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/set-og-hoert-paa-folkemoedet-demokratifesten-er-tilbage-for-fuld-skrald",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/emne/7427",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/2008-3-23-kontakt-til-altingetdk",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/staben",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/annoncer",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/civilsamfundetsvidenscenter",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/civilsamfundetsvidenscenter/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/uddannelser",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/uddannelser/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/navne",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/userforgotpassword",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  }
]